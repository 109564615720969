html,
body {
  padding: 0;
  margin: 0;
}

textarea {
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: #DCDBE0;
  border-radius: 5px;
}
